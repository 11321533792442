<script setup lang="ts">
import { ref, watch, useTemplateRef, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useHelpStore } from '@shared/store/help'
import { marked } from 'marked'
import { nextTick } from 'vue'

// load the current panel width
let helpWidthNum = 400
try {
  let temp = localStorage.getItem('helpWidth')
  if (temp) {
    helpWidthNum = parseInt(temp)
  }
} catch (e) { }

const helpWidth = ref(helpWidthNum)
const help = useHelpStore()
const helpContext = help.getContext()
const helpDrawer = useTemplateRef('helpDrawerEl')
const router = useRouter()
const imagePop = (e: MouseEvent) => {
    const target = e.target as any
    if(target && target.src) {
        window.open(target.src, '_blank')
    }
    if (target && target.href) {
        let fullPath = target.href.replace(window.location.origin, '')
        let path = fullPath.split('#')[0]
        let hash = fullPath.split('#')[1]

        hash.split('&').forEach((h:string) => {
            let parts = h.split('=')
            if (parts.length == 2 && parts[0] == 'help') {
            help.setContext(parts[1])
            }
        })
        router.push(path)
        e.preventDefault()

        return false;
    }
}

watch(helpWidth, (val) => {
  localStorage.setItem('helpWidth', val.toString())
})

const setEvents = () => {
    const minSize = 3;
    const help = document.querySelector('.help') as HTMLElement
    if (!help) {
        return
    }

    const drawerBorder = document.querySelector(".v-navigation-drawer__border") as HTMLElement;
    const direction = "right";

    const resize = (e: MouseEvent) => {
        document.body.style.cursor = "ew-resize";
        let f = direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        help.style.width = f + "px";
        helpWidth.value = f
        e.preventDefault();
    }

    const finish = function() {
        help.style.transition ='';
        document.body.style.cursor = '';
        document.removeEventListener("mousemove", resize, false);
        document.removeEventListener("mousemove", finish, false);
    }

    drawerBorder.addEventListener("mousedown", function (e) {      
    if (e.offsetX < minSize) {
        e.preventDefault();
        help.style.transition = 'initial';
        document.addEventListener("mousemove", resize, false);
        document.addEventListener("mouseup", finish, false);
        }
    }, false);
}

onMounted(() => {

    // initialize
    let ready = window.setInterval(() => {
    if (helpDrawer.value) {
        setEvents()
        clearInterval(ready)
    }
    }, 100)
})

const content = computed(() => {
    return marked.parse(helpContext?.value.record.content || '')
})


</script>
<template>
    <v-navigation-drawer
      ref="helpDrawerEl"
      :model-value="helpContext.visible" 
      class="help"
      app
      location="right"
      :width="helpWidth"
      permanent>
      <div class="v-navigation-drawer__border"></div>
      <template v-slot:prepend>
          <v-list-item
            lines="two"
            class="logo"
            prepend-avatar="/favicon.svg"
            :subtitle="helpContext.record?.title"
            title="LiveSwitch Exchange Help"
          >
          <template v-slot:append>
            <v-list-item-action @click="helpContext.visible = false">
              <v-icon style="cursor: pointer;">mdi-close</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-card elevation="0">
        
        <v-card-text @click.stop="imagePop" class="help-content" v-html="content">
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
</template>
<style>
/* tried scoped() deep() etc, no luck, so just global styling it */

.help{
  border-left:1px solid #ccc;
}
.help .v-card{
  background: transparent;
}
/* help text popup in the bottom sheet */
.help .help-content p {
  margin-bottom:16px;
}
.help .help-content ol,
.help .help-content ul{
  list-style-position: inside;
  margin-bottom:16px;
}
.help .help-content pre{
  margin-bottom: 16px;
}
/* help text popup in the bottom sheet */
.help .help-content img{
  max-height:200px;
  max-width: 80%;
  cursor: pointer;
  display:block;
  margin:auto;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
}
.help .help-content .image-title{
  padding-top:12px;
  display:block;
  margin:auto;
  width:max-content;
  font-style: italic;
  font-size:12px;
}
.help .v-navigation-drawer__border{
  position:absolute;
  right:auto;
  left:0;
  top:0;
  height:100%;
  cursor: ew-resize;
  width:3px;
  z-index: 1;
  background-color: #eee;
}
.help .logo img{
  padding:4px;
}
.help table th{
  white-space:nowrap;
}
.help table th,
.help table td{
  padding:8px;
}

.help h1{
  font-size:1.4em;
}
.help h2{
  font-size:1.3em;
}
.help h3{
  font-size:1.2em;
}
.help h4{
  font-size:1.0em;
}
.help code{
	font-size:0.9em;
	background: rgba(var(--v-theme-primary), 0.05);
  display: inline-block;
  border:1px solid #ccc;
  padding:8px;
}

.help .pulse-active {
  animation: colorPulse 1s ease-out;
}

@keyframes colorPulse {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffeb3b;  /* Change this color as needed */
  }
  100% {
    background-color: transparent;
  }
}
</style>