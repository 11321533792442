<script setup lang="ts">
import { useHelpStore } from '@shared/store/help'
import { computed, useAttrs, ref } from 'vue'
import { codeNameRules } from '@/utils/validation';

const attrs = useAttrs()
const props = defineProps({
    helpKey: { type: String, required: true },
    helpSection: { type: String, default: '' },
    showQuestion: { type: Boolean, default: false },
    showLearn: { type: Boolean, default: true }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})

const link = computed(() => {
    return `#help=${props.helpKey}&help-section=${props.helpSection}`
})

const go = () => {
    history.replaceState({}, '', link.value);
}

</script>
<template>
    <v-switch
        v-bind="$attrs"
        :persistent-hint="true"
        >
        <template v-slot:message="{ message }">
            <span style="padding-left:20px" v-html="message"></span> <span v-if="showLearn"><a style="cursor: pointer;" class="learn-more" @click.stop.prevent="go">Learn more</a>.</span>
        </template>
    </v-switch>
</template>