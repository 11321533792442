import DefaultLayout from '@/layouts/DefaultLayout.vue'

import InputFieldEdit from '@/components/platforms/sub-subcomponents/InputFieldEdit.vue'

const routeNames = {
	home: 'home',
	appsList: 'apps-list',
	appsEdit: 'apps-edit',
	connectionsEdit: 'connections-edit',
	workflowsList: 'workflows-list',
	workflowsEdit: 'workflows-edit',
	errorsList: 'errors-list',
	errorsEdit: 'errors-edit',
	platformsList: 'platforms-list',
	platformsEdit: 'platforms-edit',
	platformsEditOverview: 'platforms-edit-overview',
	platformsEditGeneral: 'platforms-edit-general',
	platformsEditAuthentication: 'platforms-edit-authentication',
	platformsActions: 'platforms-actions',
	settings: 'settings',
	logout: 'logout',
	unauthorized: 'unauthorized',
	platformsActionsEdit: 'platforms-actions-edit',
	platformsActionsApi: 'platforms-actions-api',
	platformsActionsEditSettings: 'platforms-actions-edit-settings',
	platformsActionsEditInputs: 'platforms-actions-edit-inputs',
	platformsActionsEditInputsFieldList: 'platforms-actions-edit-inputs-field-list',
	platformsActionsEditInputsFieldEdit: 'platforms-actions-edit-inputs-field-edit',
	platformsActionsEditOutputs: 'platforms-actions-edit-outputs',
	platformsActionsEditOutputsFieldList: 'platforms-actions-edit-outputs-field-list',
	platformsActionsEditOutputsFieldEdit: 'platforms-actions-edit-outputs-field-edit',
	platformsTriggers: 'platforms-triggers',
	platformsTriggersEdit: 'platforms-triggers-edit',
	platformsTriggersEditSettings: 'platforms-triggers-edit-settings',
	platformsTriggersEditOutputs: 'platforms-triggers-edit-outputs',
	platformsTriggersEditDataPropertyList: 'platforms-triggers-edit-data-property-list',
	platformsTriggersEditDataPropertyEdit: 'platforms-triggers-edit-data-property-edit',
	platformsTriggersEditInputsFieldList: 'platforms-triggers-edit-inputs-field-list',
	platformsTriggersEditInputsFieldEdit: 'platforms-triggers-edit-inputs-field-edit',
	platformsTriggersEditApi: 'platforms-triggers-edit-api'
}

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/home/HomeDashboard.vue')
	},
	{
		path: '/apps',
		name: routeNames.appsList,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/apps/AppsList.vue'),
	},
	{
		path: '/apps/:id',
		name: routeNames.appsEdit,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/apps/AppsEdit.vue'),
	},
	{
		path: '/workflows',
		name: routeNames.workflowsList,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/workflows/WorkflowsList.vue'),
	},
	{
		path: '/apps/:appId/workflows/:id',
		props: true,
		name: routeNames.workflowsEdit,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/workflows/WorkflowsEdit.vue'),
	},
	{
		path: '/errors',
		name: routeNames.errorsList,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/errors/ErrorsList.vue'),
	},
	{
		path: '/errors/:id',
		name: routeNames.errorsEdit,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/errors/ErrorsEdit.vue'),
	},
	{
		path: '/platforms',
		name: routeNames.platformsList,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/platforms/PlatformsList.vue'),
	},
	{
		path: '/platforms/:platformId',
		name: routeNames.platformsEdit,
		props: true,
		component: () => import('@/components/platforms/PlatformsEdit.vue'),
		children: [{
			path: 'overview',
			name: routeNames.platformsEditOverview,
			props: true,
			component: () => import('@/components/platforms/components/Overview.vue'),
		},{
			path: 'general',
			name: routeNames.platformsEditGeneral,
			props: true,
			component: () => import('@/components/platforms/components/General.vue'),
		}, {
			path: 'authentication',
			name: routeNames.platformsEditAuthentication,
			props: true,
			component: () => import('@/components/platforms/components/Authentication.vue'),
		}, {
			path: 'actions',
			props: true,
			name: routeNames.platformsActions,
			component: () => import('@/components/platforms/components/Actions.vue'),
		}, {
			path: 'actions/:actionId',
			props: true,
			name: routeNames.platformsActionsEdit,
			component: () => import('@/components/platforms/subcomponents/ActionsTabs.vue'),
			children: [{
				path: 'settings',
				props: true,
				name: 'platforms-actions-edit-settings',
				component: () => import('@/components/platforms/subcomponents/ActionsSettings.vue'),
			}, {
				path: 'inputs',
				props: true,
				name: 'platforms-actions-edit-inputs',
				component: () => import('@/components/platforms/subcomponents/ActionsInputs.vue'),
				children: [{
					path: '',
					props: true,
					name: routeNames.platformsActionsEditInputsFieldList,
					component: () => import('@/components/platforms/sub-subcomponents/InputFieldsList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: routeNames.platformsActionsEditInputsFieldEdit,
					meta: {
						sheetTitle: 'Edit Input Field'
					},
					components: {
						default: () => import('@/components/platforms/sub-subcomponents/InputFieldsList.vue'),
						bottomSheet: InputFieldEdit
					}
				}]
			},{
				path: 'outputs',
				props: true,
				name: 'platforms-actions-edit-outputs',
				component: () => import('@/components/platforms/subcomponents/ActionsOutputs.vue'),
				children: [{
					path: '',
					props: true,
					name: 'platforms-actions-edit-outputs-field-list',
					component: () => import('@/components/platforms/sub-subcomponents/OutputFieldsList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: 'platforms-actions-edit-outputs-field-edit',
					meta: {
						sheetTitle: 'Edit Output Field'
					},
					components: {
						default: () => import('@/components/platforms/sub-subcomponents/OutputFieldsList.vue'),
						bottomSheet: () => import('@/components/platforms/sub-subcomponents/OutputFieldEdit.vue')
					}
				}]
			}, {
				path: 'api',
				meta: {
					breadcrumb: 'API'
				},
				props: true,
				name: routeNames.platformsActionsApi,
				component: () => import('@/components/platforms/subcomponents/ActionsApi.vue'),
			}]
		}, {
			path: 'triggers',
			props: true,
			name: routeNames.platformsTriggers,
			component: () => import('@/components/platforms/components/Triggers.vue'),
		},{
			path: 'triggers/:triggerId',
			props: true,
			name: routeNames.platformsTriggersEdit,
			component: () => import('@/components/platforms/subcomponents/TriggersTabs.vue'),
			children: [{
				path: 'settings',
				props: true,
				name: routeNames.platformsTriggersEditSettings,
				component: () => import('@/components/platforms/subcomponents/TriggersSettings.vue'),
			}, {
				path: 'inputs',
				props: true,
				name: 'platforms-triggers-edit-inputs',
				component: () => import('@/components/platforms/subcomponents/TriggersInputs.vue'),
				children: [{
					path: '',
					props: true,
					name: routeNames.platformsTriggersEditInputsFieldList,
					component: () => import('@/components/platforms/sub-subcomponents/InputFieldsList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: routeNames.platformsTriggersEditInputsFieldEdit,
					component: () => import('@/components/platforms/sub-subcomponents/InputFieldEdit.vue'),
				}]
			},{
				path: 'outputs',
				props: true,
				name: routeNames.platformsTriggersEditOutputs,
				component: () => import('@/components/platforms/subcomponents/TriggersOutputs.vue'),
				children: [{
					path: '',
					props: true,
					name: routeNames.platformsTriggersEditDataPropertyList,
					component: () => import('@/components/platforms/sub-subcomponents/TriggersDataPropertiesList.vue'),
				}, {
					path: ':fieldId',
					props: true,
					name: routeNames.platformsTriggersEditDataPropertyEdit,
					//component: () => import('@/components/platforms/sub-subcomponents/TriggersDataPropertyEdit.vue'),
					meta: {
						sheetTitle: 'Edit Output Field'
					},
					components: {
						default: () => import('@/components/platforms/sub-subcomponents/TriggersDataPropertiesList.vue'),
						bottomSheet: () => import('@/components/platforms/sub-subcomponents/TriggersDataPropertyEdit.vue')
					}
				}]
			},{
				path: 'api',
				props: true,
				name: routeNames.platformsTriggersEditApi,
				component: () => import('@/components/platforms/subcomponents/TriggersApi.vue'),
			}]
		}, {
			path: 'tenants',
			props: true,
			component: () => import('@/components/platforms/components/Tenants.vue'),
		}, {
			path: 'publish',
			props: true,
			component: () => import('@/components/platforms/components/Publish.vue'),
		}]
	},
	{
		path: '/settings',
		name: routeNames.settings,
		meta: {
			layout: DefaultLayout
		},
		component: () => import('@/components/settings/SettingsEdit.vue'),
	},
	{
		path: '/callback',
		redirect: (to: any) => {
			return { path: '/' }
		}
	},
	{
		path: '/token',
		// somewhat unnecessary, we don't actually render this, we just
		// catch it in the guard below
		component: () => import('@/views/Unauthorized.vue')
	},
	{
		path: '/logout',
		name: routeNames.logout,
		redirect: (to: any) => {
			return { path: '/' }
		}
	},
	{
		path: '/unauthorized',
		name: routeNames.unauthorized,
		component: () => import('@/views/Unauthorized.vue')
	}
];

export { routes, routeNames }