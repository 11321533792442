<script lang="ts" setup>

import { useActionsStore } from '@shared/store/actions';
import { useTriggersStore } from '@shared/store/triggers';
import { useEnumsStore } from '@shared/store/enums';
import { ActionDto, InputPropertyDto, JsonPropertyType, SchemaType } from '@shared/types/api';
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { VNumberInput } from 'vuetify/labs/VNumberInput'
import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import SuccessAlert from '@/components/alerts/SuccessAlert.vue'
import Loader from '@/components/Loader.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import { routeNames } from '@/router/routes';
import { onBeforeRouteLeave } from 'vue-router';
import { confirmNavigation } from '@/utils/confirm';
import { isUuid } from '@shared/utils/uuid';

const props = defineProps<{ 
    actionId?: string, 
    triggerId?: string, 
    platformId: string, 
    fieldId: string 
}>()

if(!props.actionId && !props.triggerId){
    throw new Error('Either actionId or triggerId must be provided')
}

const enumsStore = useEnumsStore()
const field = ref<InputPropertyDto>({})
const actionsStore = useActionsStore()
const triggersStore = useTriggersStore()
const loading = ref(true);
const action = ref({} as ActionDto)
const showError = ref(false)
const showSuccess = ref(false)
const error = ref({})
const successText = 'Field saved successfully.'
const isUuidField = ref(false)

const store = props.actionId ? actionsStore : triggersStore
const id = props.actionId ? props.actionId : props.triggerId
const name = props.actionId ? 'Action' : 'Trigger'
const showApi = props.actionId ? true : false
const propertyNameIsUuidOrBlank = ref(true)

store.detail(id!).then((data) => {
    
    action.value = data
    field.value = data.inputs?.find(i => i.id === props.fieldId) ?? {}
    if (isUuid(field.value.propertyName || '')) {
        isUuidField.value = false
        field.value.propertyName = ''
    }

    originalJson.value = JSON.stringify(field.value)
    propertyNameIsUuidOrBlank.value = field.value.propertyName == '' || isUuid(field.value.propertyName!);

}).finally(() => {
    loading.value = false;
});

const router = useRouter()
const getPreviousRoute = () => window.location.pathname.split('/').slice(0, -1).join('/')

const finishBack = async () => {
    if (!field.value.propertyName) {
        await store.deleteInputProperty(id!, props.fieldId)
    }
    // router.go(-1) doesn't work
    router.push(getPreviousRoute())
}

const back = async () => {
    if (pendingChanges.value) {
        confirmNavigation(async () => {
            await finishBack()
        })
    } else {
        await finishBack()
    }
}
const readonly = ref(false)
const saving = ref(false)
const save = async () => {
    saving.value = true
    try {
        if(field.value.propertyName === ''){
            throw new Error('Property Name is required')
        }
        await store.updateInputProperty(id!, props.fieldId, field.value)
        originalJson.value = JSON.stringify(field.value)
        propertyNameIsUuidOrBlank.value = field.value.propertyName == '' || isUuid(field.value.propertyName!);
        
        showSuccess.value = true
        showError.value = false
        back()
    } catch (e: any) {
        showError.value = true
        error.value = e
    } finally {
        saving.value = false
    }
}

const originalJson = ref('')
const pendingChanges = computed(() => {
    if(originalJson.value === ''){
        return false;
    }
    return JSON.stringify(field.value) !== originalJson.value
});

enum tabs{
    general = 'general',
    advanced = 'advanced',
}
const activeTab = ref<tabs>(tabs.general)
</script>
<template>
    <div>
        <div class="d-flex tab-header-container" v-if="!loading">
            <v-card-title style="flex-grow:1">Edit Input Field</v-card-title> 
            <back-btn :disabled="loading" :loading="saving" @click.stop.prevent="back">Back</back-btn> 
            <save-btn :disabled="loading || !pendingChanges" :loading="saving" @click="save">Save</save-btn> 
        </div>
        <v-divider class="my-4"></v-divider>

        <ErrorAlert :show="showError" :error="error" @click:close="showError=false" class="my-4"></ErrorAlert>
        <SuccessAlert :show="showSuccess" :text="successText" @click:close="showSuccess=false" class="my-4"></SuccessAlert>

        <Loader v-if="loading" />

        <div v-else>
            <v-tabs v-model="activeTab">
                <v-tab :value="tabs.general">General</v-tab>
                <v-tab :value="tabs.advanced">Advanced</v-tab>
            </v-tabs>
            <v-window v-model="activeTab">
                <!-- general tab -->
                <v-window-item :value="tabs.general">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="field.displayName"
                                label="Label"
                                hint="Enter a user friendly name for this field that describes what it is. Shown to users inside Exchange. Example: Ticket ID"
                                :persistent-hint="true"
                                :rules="[v => !!v || 'Label is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="field.propertyName"
                                label="Property Name"
                                required
                                :disabled="!propertyNameIsUuidOrBlank"
                                hint="Enter the name of the field as it will be referenced in the API. Example: ticket_id. Once this value is set, it CANNOT be changed."
                                :persistent-hint="true"
                                :rules="[v => !!v || 'Property Name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" :md="showApi ? 3 : 6">
                            <v-select
                                v-model="field.propertyType"
                                label="Type"
                                required
                                hint="Select the type of data this field will contain."
                                :persistent-hint="true"
                                :rules="[(v:any) => !!v || 'Type is required']"
                                :items="enumsStore.jsonPropertyTypesList()"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" md="3" v-if="showApi">
                            <v-select
                                v-model="field.schemaType"
                                label="API Location"
                                required
                                hint="Where in the API this field will be sent."
                                :persistent-hint="true"
                                :rules="[(v:any) => !!v || 'API Location is required']"
                                :items="enumsStore.schemaTypesList()"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="field.description"
                                label="Description"
                                rows="3"
                                hint="A description of the field and its purpose. Example: The unique identifier for the ticket."
                                :persistent-hint="true"
                                counter
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-checkbox
                                v-model="field.required"
                                label="Required"
                                :persistent-hint="true"
                                hint="If enabled, this field must be provided in the input data."
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-window-item>
                <!-- advanced tab -->
                <v-window-item :value="tabs.advanced">
                    <v-row>
                        <v-col cols="12" v-if="field.propertyType == JsonPropertyType.Number">
                            <v-select
                                v-model="field.format"
                                label="Number Format"
                                required
                                hint="What kind of number field is this? Example: Float"
                                :persistent-hint="true"
                                :items="['Float', 'Double']"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" v-if="field.propertyType == JsonPropertyType.Integer">
                            <v-select
                                v-model="field.format"
                                label="Number Format"
                                required
                                hint="What kind of integer field is this? Example: Int32"
                                :persistent-hint="true"
                                :items="['Int32', 'Int64']"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.Integer || field.propertyType == JsonPropertyType.Number">
                            <VNumberInput
                                v-model="field.minimum"
                                label="Minimum"
                                required
                                hint="The minimum value for this field. Minimum and maximum are included in the range."
                                :persistent-hint="true"
                            ></VNumberInput>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.Integer || field.propertyType == JsonPropertyType.Number">
                            <VNumberInput
                                v-model="field.maximum"
                                label="Maximum"

                                required
                                hint="The maximum value for this field. Minimum and maximum are included in the range."
                                :persistent-hint="true"
                            ></VNumberInput>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.String">
                            <VNumberInput
                                v-model="field.minLength"
                                label="Minimum Length"
                                required
                                hint="The minimum length for this field. Minimum and maximum are included in the range."
                                :persistent-hint="true"
                            ></VNumberInput>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.String">
                            <VNumberInput
                                v-model="field.maxLength"
                                label="Maximum Length"
                                required
                                hint="The maximum length for this field. Minimum and maximum are included in the range."
                                :persistent-hint="true"
                            ></VNumberInput>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.String">
                            <v-select
                                v-model="field.format"
                                label="Text Format"
                                required
                                hint="The type of data stored in this text field. Example: Email. For more complex formats, use the 'Pattern' property. Use only one of 'Pattern' or 'Template', not both."
                                :persistent-hint="true"
                                :items="enumsStore.stringFormatsList()"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6" v-if="field.propertyType == JsonPropertyType.String">
                            <v-text-field
                                v-model="field.pattern"
                                label="Pattern"
                                required
                                hint="A regular expression template for the string value. Example: ^\d{3}-\d{2}-\d{4}$ for SSN. Use only one of 'Pattern' or 'Template', not both."
                                :persistent-hint="true"
                            ></v-text-field>
                        </v-col>
                        
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="field.defaultValue"
                                label="Default Value"
                                required
                                hint="A default value to fill in the field. For arrays, separate values with commas. Example: 0, 1, 2"
                                :persistent-hint="true"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-combobox
                                v-model="field.enum"
                                label="Dropdown Choices"
                                hint="If this field has predefined values, enter them here. Type, and when you are done, hit enter to add your typed value. Example: High, Medium, Low"
                                :persistent-hint="true"
                                :items="field.enum || []"
                                :multiple="true"
                                :chips="true">
                            </v-combobox>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-checkbox
                                v-model="field.nullable"
                                label="Nullable"
                                :persistent-hint="true"
                                hint="If enabled, this field may have a null value. Note that null is not the same not being defined, or a null-ish value like '', so a field may still be required and nullable."
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-checkbox
                                v-model="field.hideFromAppDevelopers"
                                label="Hide from App Developers"
                                :persistent-hint="true"
                                hint="If enabled, this field will not show up for App developers. You can still use it in your Platform definitions."
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-window-item>
            </v-window>
            
        </div>
    </div>
</template>