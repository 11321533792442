// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { useSelfStore } from '../store/self'
import { useApiStore } from '../store/api'

// https://itnext.io/3-ways-to-create-a-multiple-layouts-system-vue-3-b24c0736b7bb

// nested routes
// https://router.vuejs.org/guide/essentials/nested-routes
// tabs with routes
// https://jsfiddle.net/thorne51/9g2zeow1/2/

const buildRouter = (routes: any, url: string) => {

	const router = createRouter({
		history: createWebHistory(url),
		routes,
		strict: true,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { top: 0 }
			}
		}
	})

	router.beforeEach(async (to, from) => {
		const selfStore = useSelfStore();
		const apiStore = useApiStore();
		if (!selfStore.isUserSet()) {
			if (to.path == '/token') {
				// TODO: store the token in cache? what's our expiration?
				apiStore.setToken(JSON.parse(to.query.tokenJson as string))
				if (localStorage.getItem('login_redirect')) {
					var value = localStorage.getItem('login_redirect')
					localStorage.removeItem('login_redirect')
					window.location.href = value!
				} else {
					await selfStore.refreshUser()
					return { name: 'home' }
				}
			} else if (apiStore.getToken()) {
				// got a token; if it's expired, we'll get a 401 and redirect to /token automatically
				await selfStore.refreshUser()
				return true
			} else {
				// no token, just go to login
				window.setTimeout(() => {

					if (!localStorage.getItem('login_redirect')) {
						localStorage.setItem('login_redirect', window.location.href)
					}
					window.location.href = (import.meta as any).env.VITE_API_ROOT + '/api/authentication/login'
				}, 1)
				return false
			}
		}
	})

	router.onError((error, to) => {
		if (
			error?.message?.includes("Failed to fetch dynamically imported module") ||
			error?.message?.includes("Failed to load module script")
		) {
			if (window.location.href.includes('localhost')) {
				console.error('There was an error loading the page.', error);
				// i think this is wrong in the sub-app; fullPath doesn't include the base path 
				// of the router
				alert('failure to load page; navigating via refresh to ' + to.fullPath)
				window.location.href = to.fullPath
			} else {
				// does a redirect at the page level which is a bit jarring, but 
				// forces all the scripts to reload
				window.location.href = to.fullPath
			}
		}
	});

	return router
};

export { buildRouter }