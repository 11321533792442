///authorization/roles
import { defineStore } from 'pinia'
import { useApiStore } from './api'
import { TriggerDto, TriggersFromOpenApiDto, InputPropertyDto, OrderedRecordDto } from '../types/api'
import { isUuid } from '../utils/uuid'
import { ref } from 'vue'
import { withCache } from './cache'

export const useTriggersStore = defineStore('triggersStore', () =>
{   
    const store = useApiStore();

    let list = async (o?: {platformId : string}) => (await store.api.triggers.triggersList(o)).data;

    //let listForApp = async (o?: { appId: string }) => (await store.api.triggers.triggersAppList(o)).data;
    
    const listPublic = withCache(
        async (o?: { platformId: string }) => (await store.api.triggers.triggersPublicList(o)).data,
        {
            keyFn: (o) => `trigger_${o?.platformId || 'default'}`
        }
    );
    
    let detail = async (id: string) => (await store.api.triggers.triggersDetail(id)).data;

    const detailPublic = withCache(
        async (id: string) => (await store.api.triggers.triggersPublicDetail(id)).data,
        {
            keyFn: (id) => `trigger_detail_${id}`
        }
    );

    let update = async (id: string, data: TriggerDto) => (await store.api.triggers.triggersUpdate(id, data)).data;

    let create = async (data: TriggerDto) => (await store.api.triggers.triggersCreate(data)).data;

    let createFromOpenApi = async (data: TriggersFromOpenApiDto) => (await store.api.triggers.triggersOpenapiCreate(data)).data;

    let del = async (id: string) => (await store.api.triggers.triggersDelete(id)).data;

    let createDataProperty = async (triggerId: string, data: any) => (await store.api.triggers.triggersDataPropertiesCreate(triggerId, data)).data;

    let updateDataProperty = async (triggerId: string, id: string, data: any) => (await store.api.triggers.triggersDataPropertiesUpdate(triggerId, id, data)).data;

    let deleteDataProperty = async (triggerId: string, id: string) => (await store.api.triggers.triggersDataPropertiesDelete(triggerId, id)).data;

    let createInputProperty = async (actionId: string, data: InputPropertyDto) => (await store.api.triggers.triggersInputPropertiesCreate(actionId, data)).data; 
        
    let updateInputProperty = async (actionId: string, id: string, data: InputPropertyDto) => (await store.api.triggers.triggersInputPropertiesUpdate(actionId, id, data)).data;
        
    let deleteInputProperty = async (actionId: string, id: string) => (await store.api.triggers.triggersInputPropertiesDelete(actionId, id)).data;
    
    let updateInputPropertyOrder = async (actionId: string, data: OrderedRecordDto[]) => (await store.api.triggers.triggersInputPropertiesOrderUpdate(actionId, data)).data;
    
    let tryTrigger = async (triggerId: string) => (await store.api.triggers.triggersTryCreate(triggerId)).data;

    let stopTryingTrigger = async (triggerId: string) => (await store.api.triggers.triggersTryDelete(triggerId)).data;

    let succeedTrigger = async (id: string) => (await store.api.triggers.triggersSucceedCreate(id)).data;

    // this is used by the action editor to keep track of the current action
    const instance = ref<TriggerDto | null>(null)

    const instanceIsLoading = ref(false)

    const detailInstance = async (actionId: string): Promise<TriggerDto> => {
        instanceIsLoading.value = true
        try {
            const data = await detail(actionId) // Your API call here
            
            // Process codeName if it's a UUID
            if (isUuid(data.codeName || '')) {
                data.codeName = ''
            }
            
            // Set the instance
            instance.value = data
            return data
        } finally {
            instanceIsLoading.value = false
        }
    }


    return {
        detail, detailPublic, list, listPublic, update, create, del,
        createFromOpenApi,
        tryTrigger, stopTryingTrigger, succeedTrigger,
        detailInstance, instance, instanceIsLoading,
        createInputProperty, updateInputProperty, deleteInputProperty,updateInputPropertyOrder,
        createDataProperty, updateDataProperty, deleteDataProperty
    }
});