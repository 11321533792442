/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from '@shared/plugins/vuetify'
import pinia from '@shared/store'
import { buildRouter } from '@shared/router'
import { routes } from '../router/routes'
import { loadSentry } from '@shared/plugins/sentry'


// Types
import type { App } from 'vue'

const router = buildRouter(routes, process.env.BASE_URL!)

export function registerPlugins (app: App) {
    loadSentry(app, router)
    app
      .use(vuetify)
      .use(router)
      .use(pinia);
}
