<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useSelfStore } from '@shared/store/self';
import Notifier from '@/components/extensions/notifier';
import { useDisplay } from 'vuetify'
import Logo from '@shared/components/icons/Logo.vue'


  const { smAndDown } = useDisplay();
  const isMenuOpen = ref(false);
  
  const selfStore = useSelfStore();
  

  const profileImage = () =>{
    //TODO: add image back from self store
    return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='; // blank gif
  }

const logout = () => {
  let url = import.meta.env.VITE_AUTH_DOMAIN;
  let clientId = import.meta.env.VITE_AUTH_CLIENTID
  localStorage.removeItem('access_token');
  window.location.href = `https://${url}/v2/logout&client_id=${clientId}&returnTo=${encodeURIComponent(window.location.origin)}`;
}
</script>
<template>
  <v-app-bar :elevation="0" height="56" app>
    <v-app-bar-nav-icon :icon="Logo" @click.stop="$emit('menu')"></v-app-bar-nav-icon>
    <div>
      <span style="display: block;font-size: 8px;">LiveSwitch</span>
      <span style="display: block;font-weight: bold;">Exchange</span>
    </div>
   
    <template v-slot:append>
      <v-menu class="account-settings" location="bottom" :close-on-content-click="false" v-model="isMenuOpen">
        <template v-slot:activator="{ props }">
          <div style="cursor: pointer; display: flex; flex-direction: row; align-items: center;" v-bind="props">
            <v-avatar size="20">
                <img :src="profileImage()" referrerpolicy="no-referrer" width="24" height="24" />
            </v-avatar>
            <span style="padding-left:8px" class="hidden-sm-and-down">{{ selfStore.name() }}</span>
            <v-icon :class="isMenuOpen ? 'icon-chevron-up' : 'icon-chevron-down'"></v-icon>
          </div>
        </template>
        <v-list>
          <!--<v-list-item @click="editAccount">
            <template v-slot:prepend>
              <v-icon color="primary" class="icon-user-account-avatar"></v-icon>
            </template>
            <v-list-item-title class="text-primary">Account</v-list-item-title>
          </v-list-item>
          <v-list-group v-if="selfStore.showOrgSwitcher" value="Accounts">
            <template v-slot:activator="{ props }">
              <v-list-item
                width="350"
                v-bind="props"
                prepend-icon="icon-building-exit"
                title="Switch Account"
                :subtitle="selfStore.organization.name"
              ></v-list-item>
            </template>
            <v-list-item
              style="cursor: pointer"
              v-for="(item, i) in selfStore.organizations" 
              :key="i"
              :title="item.name"
              prepend-icon="icon-reply-arrow-long"
              width="350"
              @click="switchOrg(item.id)"
            ></v-list-item>
          </v-list-group>
          <v-divider v-if="selfStore.showOrgSwitcher"></v-divider>-->
          <v-list-item @click="logout()">
            <template v-slot:prepend>
              <v-icon color="primary" class="icon-door-open"></v-icon>
            </template>
            <v-list-item-title class="text-primary">Sign out from {{ selfStore.organizationName() }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>
<style>
header.v-toolbar .v-input__control{
  height:32px !important;
}
header.v-toolbar .v-field__input{
  padding:0;
  padding-top:3px;
  min-height: fit-content;
  height: fit-content;
}
.account-settings .v-list-item__prepend > .v-badge .v-icon,
.account-settings .v-list-item__prepend > .v-icon, 
.account-settings .v-list-item__append > .v-badge .v-icon, 
.account-settings .v-list-item__append > .v-icon{
  opacity: 1;
}
</style>