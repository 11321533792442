<script lang="ts" setup>
import { computed } from 'vue'
import { useHelpStore } from '@shared/store/help';

const props = defineProps({
    helpKey: { type: String, required: false },
    helpSection: { type: String, required: false },
    size: { type: String, default: 'small' },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})
</script>
<template>
    <v-icon :size="props.size" @click.stop="showHelp(props.helpKey + '#' + props.helpSection)" :color="color">
        mdi-help-circle-outline
    </v-icon>
</template>