<script setup lang="ts">
import './assets/fonts/liveswitch/liveswitch.css'
import AppBar from '@/components/Appbar.vue'
import Navigation from '@/components/Navigation.vue'
import HelpPanel from '@/components/HelpPanel.vue'
import { ref, onMounted, useTemplateRef, watch, computed } from 'vue'
import { useSelfStore } from '@shared/store/self'
import { useDisplay } from 'vuetify'
import ConfirmDialog from '@/components/extensions/ConfirmDialog.vue'
import { setDialog } from '@/utils/confirm'

const loadingApp = ref(true)
const confirmDialog = useTemplateRef<typeof ConfirmDialog>('confirm')
onMounted(() => {
    const selfStore = useSelfStore()
    selfStore.onUserSet(() => {
      loadingApp.value = false
    })
    if(selfStore.isUserSet()) {
      loadingApp.value = false
    }
	
	const saveConfirmDialog = () => {
		if (!confirmDialog.value) {
			window.setTimeout(saveConfirmDialog, 100)
		} else {
			setDialog(confirmDialog.value)
		}
	}
	saveConfirmDialog()
})

const drawer = ref(true)
const menu = () => {
    drawer.value = !drawer.value
}

const { smAndDown } = useDisplay();


</script>
<template>
  <v-app id="app" :class="smAndDown ? 'mobile' : ''">

    <ConfirmDialog ref="confirm"></ConfirmDialog>

    <app-bar @menu="menu" v-if="!loadingApp"></app-bar>
    <navigation v-model="drawer" v-if="!loadingApp"></navigation>
    <v-main class="d-flex justify-center" v-if="!loadingApp">
      <router-view></router-view>
      
    </v-main>
    <v-main class="d-flex justify-center" v-else >
      <v-progress-linear
        indeterminate
        color="primary"
        style="margin:30px"
      >
      </v-progress-linear>
    </v-main>
    
    

    <help-panel></help-panel>

  </v-app>
</template>

<style>
  html,body,div#app {
    width: 100%;
    min-height: 100%;
    background-color: #FFF;
    font-size: 0.875rem;
  }

  #app .mobile .page{
    padding-bottom: 48px; /* space for the bottom nav so we can scroll to the bottom */
  }
  
  main .spacer{
    padding-bottom:20px;
    width:100%;
    text-align:center;
    color:#999;
  }

  .v-input .v-field--variant-solo, .v-select .v-field--variant-solo
  {
      box-shadow: none !important;
      border: 1px solid rgb(var(--v-theme-border-color));
  }

  .v-input .v-field--variant-solo .required, .v-select .v-field--variant-solo .required {
    color: rgb(var(--v-theme-blue));
  }

  .v-field--focused.v-field--variant-solo {
      border-color: rgb(var(--v-theme-blue));
  }

  .v-input--error .v-field--error.v-field--variant-solo {
      border-color: rgb(var(--v-theme-red));
      background-color: rgb(var(--v-theme-input-error-bg));
  }

  .v-card .v-card-text h1 {
      line-height: 2.5em !important;
  }
  .v-card-title{
    font-size:16px;
  }
  .v-card.border {
    border: 1px solid #ccc !important;
  }

  .required {
    color: rgb(var(--v-theme-blue));
  }

  a {
      text-decoration: none;
  }


  .v-list--nav > a.v-list-item--nav > span.v-list-item__overlay {
    background: rgb(var(--v-theme-blue));
  }

  .v-table.v-data-table {
    border-radius: 8px;
  }

  .v-btn.v-btn--variant-outlined > .v-btn__overlay {
    background-color: #FFF;
  }
  
  .v-slide-group .v-btn span.v-btn__overlay{
    background-color: #FFF;
  }

  .text-emphasis {
    color: rgb(var(--v-theme-blue));
    font-weight: bold;
  }

  .text-overline-small {
    /* Overline - Small */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 13px;
    /* identical to box height, or 144% */
    letter-spacing: 0.02em;
    text-transform: uppercase;
    /* Manatee Gray/Manatee Gray */
    color: #9B9EA5;
  }

/* nav bar title */
.navigation-bar .v-list-item--nav .v-list-item-title {
  font-size: 12px;
  letter-spacing: normal;
  line-height: 1.25rem;
}

/* hide the counter  */
.v-text-field.v-input--readonly div.v-counter,
.v-text-field.v-input--readonly textarea:read-only div.v-counter
{
  display: none;
}

/* soften up the bottom nav */
.v-bottom-navigation .v-btn__content {
  font-size: 0.688rem;
  color: rgb(var(--v-theme-bottom-nav-color));
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 0.915rem;
}

/* weird padding issue when showing hints... */
.v-input__details{
  padding-bottom:6px;
}

/*give a little extra spacing to the error/success messaging */
.default-page-card-text .v-alert{
  margin-bottom: 12px;
}

/* cap the main window size */
.v-main{
  max-height: 100dvh;
}

/* make readonly labels and inputs easier to read */
input:read-only {
  cursor: default;
}

.v-text-field input:read-only, 
.v-text-field textarea:read-only{
  color:black;
}
input:disabled{
  color:black;
  opacity: 1;
}
label.v-label{
  color:black;
  opacity:1;
}
.v-tabs-window{
  display:flex;
  flex-grow: 1;
}
.v-tabs-window .v-window__container{
  flex-grow: 1;
}

.tip{
  font-size:12px;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.tab-header-container {
    /*padding: 0 0 8px 0;*/
    gap: 8px;
    align-items: center;
}

.v-tabs .v-tab__slider{
  	display:none;
}
.v-tabs .v-tab.v-tab-item--selected{
  	color: rgb(var(--v-theme-blue));
}

/* 
fixes funky layout bug where the monaco editor will grow but not shrink
https://stackoverflow.com/a/71876526/25330
*/
.monaco-editor { position: absolute !important; }

.force-wrap{
  word-break: break-all;
}

.v-form .v-input{
  margin:12px 0;
}
.learn-more{
  text-decoration: underline;
}
.platform-edit .v-card-title{
  font-size:1.25rem;
}
a.learn-more{
  color: rgb(var(--v-theme-primary));
}
a{
  color: rgb(var(--v-theme-primary));
}
.v-alert a{
  color: rgb(var(--v-theme-primary-light));
  text-decoration: underline;
}
</style>