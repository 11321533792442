<script lang="ts" setup>
import { computed } from 'vue'
import { useHelpStore } from '@shared/store/help';

const props = defineProps({
    helpKey: { type: String, required: true },
    helpSection: { type: String, default: '' },
    size: { type: String, default: 'small' },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'blue' : 'darkgrey'
})
</script>
<template>
    <a href="#" @click.stop.prevent="showHelp(props.helpKey + '#' + props.helpSection)" :class="`background-color:${color}`">
        <slot>Learn more</slot>
    </a><span v-if="!$slots.default">.</span>
</template>