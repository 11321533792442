export interface CacheOptions {
    ttl?: number;
    keyFn?: (...args: any[]) => string;
}

export function withCache<T>(
    fn: (...args: any[]) => Promise<T>,
    options: CacheOptions = {}
  ): (...args: any[]) => Promise<T> {
    const cache = new Map<string, any>();
    const ttl = options.ttl || 5 * 60 * 1000;
    const keyFn = options.keyFn || ((...args) => JSON.stringify(args));
  
    return async (...args) => {
      const key = keyFn(...args);
      const cached = cache.get(key);
  
      if (cached?.timestamp > Date.now() - ttl) {
        return cached.data;
      }
  
      const data = await fn(...args);
      cache.set(key, { data, timestamp: Date.now() });
      return data;
    };
}