
///authorization/roles
import { defineStore } from 'pinia'
import { nextTick, ref, watch } from 'vue'

// each record is title + content; we parse the content as markdown
export interface HelpRecord{
    title: string;
    content: string;
}
// context = visibility + record
export interface HelpContext{
    visible: boolean;
    key: string | null;
    anchor: string | null;
    record: HelpRecord
}
// dictionary of records so we can have typed data from the json
interface HelpDictionary {
    [key: string]: HelpRecord;
}

export const useHelpStore = defineStore('helpStore', () =>
{   
    const rootSelector = '.help'

    const context = ref({
        visible: false,
        record: {
            title: 'Default',
            content: 'No help available'
        }
    } as HelpContext);

    let lastKey = '' as string | null
    let lastAnchor = '' as string | null
    const setContext = (key: string | null) => {
        // avoid flickering by clicking on the same button over and over
        let parts = key?.split('#') ?? []
        key = parts?.[0] ?? '';
        let anchor = parts[1] || '';
        if (key == lastKey && context.value.visible) {
            if (!anchor) {
                key = null
            }
        }


        let changedKeys = key != lastKey

        lastKey = key;
        lastAnchor = anchor;
        if (key) {
            context.value = {
                key: key,
                anchor: anchor,
                visible: true,
                record: context.value.record
            }

            if (!changedKeys) {
                highlight()
                return
            }

            context.value.record = {
                title: 'Loading',
                content: `Please wait while we load the help content...`
            }

            const moduleUrl = import.meta.url;
            const baseUrl = new URL('/assets/help/', moduleUrl).href;
            const fileUrl = new URL(context.value.key + '.md', baseUrl).href;

            
            fetch(fileUrl + '?t=' + new Date().getTime())
                .then(response => response.text())
                .then(text => {
                    let parts = text.split('\n')
                    /*
                    The content of the file looks like this. Note that title is on the first line, followed by content.
                    title: Logo URL
                    This URL should
                    support mulitple lines
                    */
                    let title = parts[0].split(':')[1].trim();
                    let content = parts.slice(1).join('\n');
                    // replace all relative URLs with absolute URLs
                    // this is a bit of a hack, but it works; probably should do it when we convert the markdown?
                    // actually probably should convert the markdown here...
                    content.match(/!\[.*\]\((.*?)\)/g)?.forEach((match) => {
                        let m = match.match(/\(.*?\)/);
                        if (m) {
                            let file = m[0].substring(1, m[0].length - 1);
                            let full = new URL(file, baseUrl).href
                            content = content.replace(file, full);
                        }
                    })
                    context.value.record.content = content;
                    context.value.record.title = title;

                    nextTick(() => {
                        highlight()
                    })

                }).catch((e) => {
                    console.error('Error loading help content for key ' + key, e)
                    context.value = {
                        key: '',
                        anchor: '',
                        visible: true,
                        record: {
                            title: 'Not Found',
                            content: 'Error loading content: ' + e
                        }
                    }
                })
            
        } else {
            context.value = {
                key: '',
                anchor: '',
                visible: false,
                record: {
                    title: 'Not Found',
                    content: 'No help available'
                }
            }
        }
    }

    const getContext = () => {
        return context;
    }

    const onChangeState = (state:any, title:any, url:any, isReplace:any) => { 
        if (!url || url.indexOf('#') == -1) { return }
        
        let context = ''
        let anchor = ''
        url.split('#')[1].split('&').forEach((h:string) => {
            let parts = h.split('=')
            if (parts.length == 2 && parts[0] == 'help') {
                context = parts[1]
            }
            if(parts.length == 2 && parts[0] == 'help-section') {
                anchor = parts[1]
            }
        })
        setContext(context + '#' + anchor)
    }
    
    // set onChangeState() listener:
    ['pushState', 'replaceState'].forEach((changeState) => {
        // store original values under underscored keys (`window.history._pushState()` and `window.history._replaceState()`):
        
        (window.history as any)['_' + changeState] = (window.history as any)[changeState]
    
        // @ts-ignore
        window.history[changeState] = new Proxy((window.history as any)[changeState], {
            apply (target, thisArg, argList) {
                const [state, title, url] = argList
                onChangeState(state, title, url, changeState === 'replaceState')
                
                return target.apply(thisArg, argList)
            },
        })
    })

    const pulse = (element: HTMLElement) => {
        // Remove existing animation if present
        element.classList.remove('pulse-active');
        
        // Force a reflow to ensure the animation triggers again
        void element.offsetWidth;
        
        // Add the pulse class
        element.classList.add('pulse-active');
        
    }

    const highlight = () => {
        let el = document.querySelectorAll(rootSelector + " *[name='" + context.value.anchor + "']")
        if (el.length) {
            el[0].scrollIntoView()
            // get the containing hX element
            let h = el[0].parentElement

            // find all the siblings until the next hX
            let currentElement = h!.nextElementSibling;

            while (currentElement && !currentElement.matches(h?.nodeName || 'h2')) {
                pulse(currentElement as HTMLElement);
                currentElement = currentElement.nextElementSibling;
            }
        } 
    }

    return { setContext, getContext }
});

