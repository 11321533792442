import * as Sentry from '@sentry/vue';
import { 
    ReportingObserver as ReportingObserverIntegration, 
    ExtraErrorData as ExtraErrorDataIntegration, 
    CaptureConsole as CaptureConsoleIntegration,  
    HttpClient as HttpClientIntegration
} from "@sentry/integrations";

export function loadSentry (app : any, router : any) {
    const environment = process.env.NODE_ENV;
    const release = process.env.APP_VERSION;
    const name = process.env.APP_NAME;
    const sentryDSN ="https://3bfdaed4a939d82537ed7270149df7c3@o4504596163002368.ingest.us.sentry.io/4508033334116352";
    console.log('Environment', environment)
    if (environment !== "development") {
        Sentry.init({
            app,
            dsn: sentryDSN,
            integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
            new ExtraErrorDataIntegration({
                // Limit of how deep the object serializer should go. Anything deeper than limit will
                // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
                // a primitive value. Defaults to 3.
                depth: 3
            }),
            new CaptureConsoleIntegration(
                {
                // array of methods that should be captured
                // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
                levels: ['warn', 'error']
                }
            ),
            new HttpClientIntegration({
                // This array can contain tuples of `[begin, end]` (both inclusive),
                // single status codes, or a combination of both.
                // default: [[500, 599]]
                failedRequestStatusCodes: [[500, 505], 507],
        
                // This array can contain Regexes, strings, or a combination of both.
                // default: [/.*/]
                failedRequestTargets: [/.*/]
            }),
            new ReportingObserverIntegration()
            ],
            environment,
            release: `${name}@${release}`,
            // Performance Monitoring
            tracesSampleRate: 1.0,
            sendDefaultPii: true,
            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}